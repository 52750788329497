<script setup>
import { ref, watch, getCurrentInstance, onMounted } from 'vue'
import { Notivue, Notification, NotificationProgress, push } from 'notivue'
import { useRoute, useRouter } from 'vue-router'
import OverlayVue from './components/Overlay.vue'

const refOverlay = ref(null)
const $route = useRoute()
const router = useRouter()
const $root = getCurrentInstance()?.appContext.config.globalProperties

onMounted(() => {
  if ($root) {
    $root.push = push
    $root.router = router
    $root.overlay = refOverlay.value
  }
})

watch(() => $route, () => {
  if ($root) {
    $root.push.clearAll()
    $root.overlay.loading = false
  }
})
</script>
<template>
  <router-view />
  <Notivue v-slot="item">
    <Notification :item="item">
      <NotificationProgress :item="item" />
    </Notification>
  </Notivue>
  <OverlayVue ref="refOverlay" />
</template>
